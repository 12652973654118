import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
@Injectable()
export class CashLogsService {
    constructor(private api: ApiService) {}
    getCashLogs(query?: any) {
        return this.api.get(this.api.path + "/logs", query);
    }
    getLogStatus() {
        return this.api.get(this.api.path + "/logs/logStatus");
    }
    getVipLevels(query?: any) {
        return this.api.get(this.api.path + "/get/all/levels", query);
    }
    updateVipLevels(id?: any, data?:any) {
        return this.api.post(this.api.path + "/update/level/" + id,data);
    }
}
